/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layingout';
import MapsVideo from '../assets/videos/vv-new.mp4';
import GridBlock from '../components/gridTwoBlock';
import TopMenu from '../components/topMenu';


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query WhyHelpImg {
      practice: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
transition: file(relativePath: { eq: "logo-slide.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appointment: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aestheticians: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doctors: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      whiterLogo: file(relativePath: { eq: "VV.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    
    
    const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    transform             : 'translate(-50%, -50%)',
    zIndex               : '99',
  }
};
    
 function closeModal(){
    setIsOpen(false);
  }    
    
  const blockCSS = 'flex flex-auto group w-full relative h-1/8 border-0';
  const colorCSS =
    'transition h-full w-full absolute opacity-10 group-hover:opacity-30';
  return (
    <Layout to="/" label="BACK TO HOME" showMenuIcon className="">
                                      <TopMenu image={data.whiterLogo}
                        >
      </TopMenu>

      <section className="h-screen w-screen">
        <div className="absolute w-screen h-screen">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full absolute"
          >
            <source src={MapsVideo} type="video/mp4" />
          </video>
          <div className="w-full h-full overlay absolute"></div>
        </div>
      <div className="relative flex h-screen w-screen justify-center flex-col">
        <div className="flex flex-wrap justify-center">
      
      
          <GridBlock
            blockCSS={`${blockCSS}`}
            colorCSS={`${colorCSS} bg-white group-hover:bg-black`}
            image={data.doctors}
            transitionImage={data.transition}
            link={'../why-network'}
            title={'Why Network Marketing?'}
          />
          <GridBlock
            blockCSS={`${blockCSS}`}
            colorCSS={`${colorCSS} bg-white group-hover:bg-black`}
            image={data.practice}
            transitionImage={data.transition}
            link={'../why-vivo'}
            title={'Why Vivo Vibe?'}
          />
        </div>
</div>
      </section>
    </Layout>
  );
};

export default IndexPage;
